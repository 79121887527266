import { getDAOAddress, getDaoUri } from './daoAPI'
import axios from '../utilities/backend/axios-firstlife-api'
import { logger } from '../utilities/logger/logger'
import { DaoData } from '../types/dao.types'
import { getFromIpfs } from './resourceAPI'
import config from '../config'
import { getCrowdsaleInfo } from './crowdsaleAPI'
import { UserNameType, getUserNameByAddress } from './userAPI'
import { Wallet } from 'ethers'

export const getFirstLifePlaceIdsOfDaos = async (): Promise<string[]> => {
    const url = 'Things/search?domain_id=17&types=FL_PLACES'
    try {
        const response = await axios.get(url)
        return response.data.things.features.map((place: any) => {
            //fixme remove any, put correct type
            return place.id
        })
    } catch (error: any) {
        logger.error(`error in getFirstLifePlaceIdsOfDaos ${error}`)
        return []
    }
}

export type PlacesDetailsType = {
    _id: string
    id: string
    address: string
    name: string
    coordinates: [number, number]
    geometry: any
    web3: any
    properties: any
}

export type PlacesDetails = {
    place: PlacesDetailsType
    daoAddress: string
    owner: any[]
    address: string
    daoDetails: any
    resources: any
}

export type CrwdslPlacesDetails = {
    crwdslAddress: string
    place: PlacesDetailsType
    owner: string
    title: string
    description: string
    logoHash: string
    TOSHash: string
    start: number
    end: number
    acceptRatio: number
    giveRatio: number
    tokenToGiveAddr: string
    tokenToAcceptAddr: string
    status: string
    maxCap: number
    ownerData?: UserNameType
}

export const getOwnedPlaces = async (
    ethersInstance: Wallet,
    userID: string
): Promise<PlacesDetailsType[]> => {
    const url = 'Things/search?types=FL_PLACES&user=' + userID + '@FIRSTLIFE'
    try {
        const response = await axios.get(url)
        let placesList = response.data.things.features
        logger.info(placesList)
        let ownedPlaces: PlacesDetailsType[] = []
        for (let flPlace of placesList) {
            const daoAddress = await getDAOAddress(ethersInstance, flPlace.id)
            if (daoAddress === null) {
                ownedPlaces.push(flPlace)
            }
        }
        logger.info(ownedPlaces)
        return ownedPlaces
    } catch (error: any) {
        logger.error(`error in getOwnedPlaces ${error}`)
        return []
    }
}

export const getAllPlacesByCoordinates = async (
    ethersInstance: Wallet,
    accountAddress: string,
    ne_lat: number,
    ne_lon: number,
    sw_lat: number,
    sw_lon: number
) => {
    let url =
        'Things/boundingbox?ne_lat=' +
        ne_lat +
        '&ne_lng=' +
        ne_lon +
        '&sw_lat=' +
        sw_lat +
        '&sw_lng=' +
        sw_lon +
        '&light=false&factoryAddress=' +
        config.smartContracts.DAO_FCTRY_ADDR +
        ',' +
        config.smartContracts.CRWDSL_FCTRY_ADDR +
        '&onlyWeb3=true'
    try {
        const response = await axios.get(url)
        let placesList = await response.data.things.features

        let placesWithDAO: PlacesDetails[] = []
        let placesWithCrowdsale: CrwdslPlacesDetails[] = []
        for (let flPlace of placesList) {
            if (
                flPlace.web3.factory_address ===
                config.smartContracts.DAO_FCTRY_ADDR
            ) {
                const daoAddress = flPlace.web3.web3_address
                if (daoAddress !== null) {
                    const daoUri = await getDaoUri(ethersInstance, daoAddress)
                    if (!daoUri) continue
                    const daoDetails = await getFromIpfs(daoUri)
                    const place: PlacesDetails = {
                        place: flPlace,
                        daoAddress: daoAddress,
                        owner: flPlace.properties.owner.display_name,
                        address: flPlace.properties.address,
                        daoDetails: daoDetails,
                        resources: flPlace.properties.resources,
                    }
                    placesWithDAO.push(place)
                }
            } else if (
                flPlace.web3.factory_address ===
                config.smartContracts.CRWDSL_FCTRY_ADDR
            ) {
                const crowdsaleAddress = flPlace.web3.web3_address
                if (crowdsaleAddress !== null) {
                    let info = await getCrowdsaleInfo(
                        ethersInstance,
                        crowdsaleAddress
                    )
                    if (!info) continue
                    let ownerDisplayName = await getUserNameByAddress(
                        info.owner
                    )
                    const crwdPlace: CrwdslPlacesDetails = {
                        crwdslAddress: crowdsaleAddress,
                        place: flPlace,
                        title: info.title,
                        description: info.description,
                        logoHash: info.logoHash,
                        TOSHash: info.TOSHash,
                        start: info.start.toNumber(),
                        end: info.end.toNumber(),
                        acceptRatio: info.acceptRatio.toNumber(),
                        giveRatio: info.giveRatio.toNumber(),
                        tokenToGiveAddr: info.tokenToGiveAddr,
                        tokenToAcceptAddr: info.tokenToAcceptAddr,
                        status: info.status,
                        maxCap: info.maxCap.toNumber(),
                        owner: info.owner,
                        ownerData: ownerDisplayName
                            ? ownerDisplayName
                            : undefined,
                    }

                    placesWithCrowdsale.push(crwdPlace)
                }
            }
        }
        logger.info(placesWithCrowdsale)
        return {
            placesWithDAO,
            placesWithCrowdsale,
        }
    } catch (error: any) {
        logger.error(`error in getAllPlacesWithDaoByCoordinates ${error}`)
        return {
            placesWithDAO: [],
            placesWithCrowdsale: [],
        }
    }
}

export const getNearPlacesWithDao = async (
    ethersInstance: Wallet,
    lat: number,
    lon: number,
    currentAddress: string
): Promise<DaoData[]> => {
    const url =
        'Things/near?lon=' +
        lon +
        '&lat=' +
        lat +
        '&distance=1000&factoryAddress=' +
        config.smartContracts.DAO_FCTRY_ADDR +
        '&onlyWeb3=true'
    try {
        const response = await axios.get(url)
        let placesList = await response.data.things.features
        logger.info(placesList)
        let nearPlaces: DaoData[] = []
        for (let flPlace of placesList) {
            if (
                flPlace.web3.factory_address !==
                config.smartContracts.DAO_FCTRY_ADDR
            )
                continue
            const daoAddress = flPlace.web3.web3_address
            if (daoAddress !== null && daoAddress !== currentAddress) {
                const daoUri = await getDaoUri(ethersInstance, daoAddress)
                if (!daoUri) continue
                const daoDetails = await getFromIpfs(daoUri)
                const daoName = daoDetails.name
                const flPlaceId = flPlace._id
                const daoType = daoDetails.type
                if (daoUri && daoDetails) {
                    const daoObj: DaoData = {
                        additional_properties: {
                            commonshoodWallet: daoAddress,
                        },
                        name: daoName,
                        flPlaceId: flPlaceId,
                        realm: 'dao',
                        type: daoType,
                    }
                    nearPlaces.push(daoObj)
                }
            }
        }
        return nearPlaces
    } catch (error: any) {
        logger.error(`error in getNearPlacesWithDao ${error}`)
        return []
    }
}

export const getAllMyPlaces = async (
    userID: string,
    ethersInstance: Wallet
) => {
    const url = 'Things/search?types=FL_PLACES&user=' + userID + '@FIRSTLIFE'
    try {
        const response = await axios.get(url)
        if (response.data) {
            let placesList = response.data.things.features
            // TODO: check crowdsale address?
            logger.info(placesList)
            return placesList
        }
        return []
    } catch (error: any) {
        logger.info(`error in getOwnedPlaces ${error}`)
        return []
    }
}

export const createNewPlace = async (
    coords: { lat: number; lng: number },
    name: string,
    description: string,
    address: string
): Promise<string> => {
    logger.info('createNewPlace', coords, name, description, address)
    try {
        let response = await axios.post(
            'Things/?Content-Type=application/json',
            {
                type: 'Feature',
                properties: {
                    categories: [
                        {
                            category_space: {
                                id: 32,
                                name: 'Spazio',
                            },
                            categories: [
                                {
                                    id: 1131,
                                    name: 'Pubblico',
                                    category_index: 1,
                                    icon_name: 'ion-paintbrush',
                                },
                            ],
                        },
                    ],
                    domain_id: 98,
                    entity_type: 'FL_PLACES',
                    status: 'PUBLIC',
                    address: address,
                    description: description,
                    name: name,
                },
                geometry: {
                    coordinates: [coords.lng, coords.lat],
                    type: 'Point',
                },
            }
        )
        logger.info(response)
        return response.data._id
    } catch (error: any) {
        logger.error(`error in createNewPlace ${error}`)
        return ''
    }
}

export const getPlaceFromAddressInput = async (address: string) => {
    try {
        const response = await fetch(
            'https://autosuggest.search.hereapi.com/v1/autosuggest?q=' +
                address +
                '&language=IT&apiKey=6P-eboQpL3_0aSRfkCXNRlxcceZUyp1Ah3zLQoWhF4c&limit5&at=45.065520,7.664315'
        )
        const data = await response.json()
        if (data.items) {
            logger.info('PlaceCreationForm.updateSuggestedAddress', data)
            return data.items
        }
    } catch (error) {
        logger.info('PlaceCreationForm.updateSuggestedAddress', error)
        return []
    }
}
